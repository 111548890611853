import iconLogo from 'assets/icons/logo.svg'
import iconLogoBig from 'assets/icons/logo-big.svg'
import s from './index.module.scss'

const Header = () => (
  <>
    <header className={s['header']}>
      <div className={s['header__wrapper']}>
        <a href='https://autoagents.io'>
          <img
            className={s['header__logo'] + ' ' + s['header__logo--small']}
            src={iconLogo}
            alt='Logo'
          />
          <img
            className={s['header__logo'] + ' ' + s['header__logo--big']}
            src={iconLogoBig}
            alt='Logo'
          />
        </a>
      </div>
    </header>
    <div className={s['header__mock']}></div>
  </>
)

export default Header
