import Form from 'components/Form'
import imageTesla from 'assets/images/tesla.png';
import imageTesla2x from 'assets/images/tesla@2x.png'
import imageTesla3x from 'assets/images/tesla@3x.png'
import imageTesla768 from 'assets/images/tesla768.png'
import imageTesla7682x from 'assets/images/tesla768@2x.png'
import imageTesla7683x from 'assets/images/tesla768@3x.png'
import imageTesla1280 from 'assets/images/tesla1280.png'
import imageTesla12802x from 'assets/images/tesla1280@2x.png'
import imageTesla12803x from 'assets/images/tesla1280@3x.png'
import imageTesla1920 from 'assets/images/tesla1920.png'
import imageTesla19202x from 'assets/images/tesla1920@2x.png'
import imageTesla19203x from 'assets/images/tesla1920@3x.png'
import s from './index.module.scss'

const Survey = ({
  onDone = () => {}
}) => {
  return (
    <div className={s['survey']}>
      <div className={s['survey__text-wrapper']}>
        <h1 className={s['survey__title']}>
          Sell or trade-in your car for top dollar with ease!
        </h1>
        <h2 className={s['survey__subtitle']}>
        Enter your cars info, receive an accurate 
        estimation from our evaluation tool, then 
        decide whether to receive a firm offer from 
        us to buy your car today.
        </h2>
      </div>
      <Form onDone={onDone} />
      <img
        className={s['survey__image'] + ' ' + s['survey__image--375']}
        src={imageTesla}
        srcSet={`${imageTesla2x} 2x, ${imageTesla3x} 3x`}
        alt='Tesla'
      />
      <img
        className={s['survey__image'] + ' ' + s['survey__image--768']}
        src={imageTesla768}
        srcSet={`${imageTesla7682x} 2x, ${imageTesla7683x} 3x`}
        alt='Tesla'
      />
      <img
        className={s['survey__image'] + ' ' + s['survey__image--1280']}
        src={imageTesla1280}
        srcSet={`${imageTesla12802x} 2x, ${imageTesla12803x} 3x`}
        alt='Tesla'
      />
      <img
        className={s['survey__image'] + ' ' + s['survey__image--1920']}
        src={imageTesla1920}
        srcSet={`${imageTesla19202x} 2x, ${imageTesla19203x} 3x`}
        alt='Tesla'
      />
    </div>
  )
}

export default Survey
