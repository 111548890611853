import { useState, useEffect } from 'react'
import { findPhoneNumbersInText } from 'libphonenumber-js'
import Loader from 'components/Loader'
import Dropdown from 'components/Dropdown'
import Input from 'components/Input'
import Button from 'components/Button'
import iconPrevStage from 'assets/icons/prev-stage.svg'
import serviceSpecifications from 'services/specifications'
import serviceVehicles from 'services/vehicles'
import utilConstants from 'utils/constants'
import utilExtractors from 'utils/extractors'
import s from './index.module.scss'

const Form = ({
  onDone = () => {}
}) => {
  const [stage, setStage] = useState('vehicle')
  const [prevStage, setPrevStage] = useState('vehicle')
  const [year, setYear] = useState('')
  const [province, setProvince] = useState('')
  const [make, setMake] = useState('')
  const [model, setModel] = useState('')
  const [trim, setTrim] = useState('')
  const [mileage, setMileage] = useState('')
  const [isErrorMileage, setIsErrorMileage] = useState(false)
  const [vin, setVin] = useState('')
  const [isErrorVin, setIsErrorVin] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [isErrorEmail, setIsErrorEmail] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isErrorPhoneNumber, setIsErrorPhoneNumber] = useState(false)
  const [uvc, setUvc] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [specifications, setSpecifications] = useState([])

  useEffect(() => {
    setIsLoading(true)
    serviceSpecifications.get().then(
      data => setSpecifications(data.items)
    ).finally(
      () => setIsLoading(false)
    )
  }, [])

  const yearOptions = specifications.map(specification => ({ value: specification.year, label: specification.year }))
  const makeOptions = year
    ? specifications.filter(specification => specification.year === year)[0].makes.map(make => ({ value: make.title, label: make.title }))
    : []
  const modelOptions = make
    ? specifications.filter(specification => specification.year === year)[0].makes.filter(_make => _make.title === make)[0].models.map(model => ({ value: model.title, label: model.title }))
    : []
  const trimOptions = model
    ? [{ value: 1, label: 'I\'m not sure' }, ...specifications.filter(specification => specification.year === year)[0].makes.filter(_make => _make.title === make)[0].models.filter(_model => _model.title === model)[0].trims.map(trim => ({ value: trim.title, label: trim.title }))]
    : []

  const titles = {
    'vehicle': 'Vehicle details',
    'vin': 'VIN Number',
    'user': 'Tell us where to send your estimated offer '
  }

  const isDisableContinueButtonVehicleStage = stage !== 'vehicle' || !year || !province || !make || !model || !trim || !mileage
  const isDisableContinueButtonVinStage = stage !== 'vin' || !mileage || vin.length !== 17 || isLoading
  const isDisableShowMeValuationResultButtonUserStage = stage !== 'user' || !firstName || !lastName || !email || !phoneNumber

  const handleChangeYear = value => {
    setTrim('')
    setModel('')
    setMake('')
    setYear(value)
    setUvc('')
  }

  const handleChangeMake = value => {
    setTrim('')
    setModel('')
    setMake(value)
    setUvc('')
  }

  const handleChangeModel = value => {
    setTrim('')
    setModel(value)
    setUvc('')
  }

  const handleChangeTrim = value => {
    setTrim(value)
    const currentTrims = specifications.filter(specification => specification.year === year)[0].makes.filter(_make => _make.title === make)[0].models.filter(_model => _model.title === model)[0].trims
    setUvc(value === 1 ? currentTrims[0].uvc : currentTrims.filter(_trim => _trim.title === value)[0].uvc)
  }

  const handleChangeMileage = value => {
    setIsErrorMileage(false)
    setMileage(value)
  }

  const handleChangeVin = value => {
    if (value.length > 17) {
      return
    }
    setIsErrorVin(false)
    setVin(value)
  }

  const handleClickLinkVehicleStage = () => {
    if (stage !== 'vehicle') {
      return
    }
    setStage('vin')
  }

  const handleClickContinueButtonVehicleStage = () => {
    if (isDisableContinueButtonVehicleStage) {
      return
    }
    const mileageRegex = /^\d+$/
    if (!mileageRegex.test(mileage)) {
      setIsErrorMileage(true)
      return
    }
    const currentTrims = specifications.filter(specification => specification.year === year)[0].makes.filter(_make => _make.title === make)[0].models.filter(_model => _model.title === model)[0].trims
    setUvc(trim === 1 ? currentTrims[0].uvc : currentTrims.filter(_trim => _trim.title === trim)[0].uvc)
    setPrevStage(stage)
    setStage('user')
  }

  const handleClickLinkVinStage = () => {
    if (stage !== 'vin') {
      return
    }
    setStage('vehicle')
  }

  const handleClickContinueButtonVinStage = () => {
    if (isDisableContinueButtonVinStage) {
      return
    }
    const mileageRegex = /^\d+$/
    if (!mileageRegex.test(mileage)) {
      setIsErrorMileage(true)
      return
    }
    setIsErrorVin(false)
    setIsLoading(true)
    serviceVehicles.getUvc(vin).then(({ uvc }) => {
      setUvc(uvc)
      setPrevStage(stage)
      setStage('user')
    }).catch(
      () => setIsErrorVin(true)
    ).finally(
      () => setIsLoading(false)
    )
  }

  const handleChangeEmail = value => {
    setIsErrorEmail(false)
    setEmail(value)
  }

  const handleChangePhoneNumber = value => {
    setIsErrorPhoneNumber(false)
    setPhoneNumber(value)
  }

  const handleClickShowMeValuationResultButtonUserStage = () => {
    if (isDisableShowMeValuationResultButtonUserStage) {
      return
    }
    let isValidationError = false
    setIsErrorEmail(false)
    const emailRegex = /\S+@\S+\.\S+/
    if (!emailRegex.test(email)) {
      setIsErrorEmail(true)
      isValidationError = true
    }
    setIsErrorPhoneNumber(false)
    const phoneNumbers = findPhoneNumbersInText(phoneNumber, 'CA')
    if (phoneNumber && !phoneNumbers.length) {
      setIsErrorPhoneNumber(true)
      isValidationError = true
    }
    if (isValidationError) {
      return
    }
    const acceptedPhoneNumber = phoneNumbers.length
      ? phoneNumbers[0].number.number
      : ''
    const predictedUserInput = specifications.map(specification => specification.makes.map(make => make.models.map(model => model.trims.map(trim => ({
      uvc: trim.uvc,
      trim: trim.title,
      model: model.title,
      make: make.title,
      year: specification.year
    }))))).flat(3).filter(specification => specification.uvc === uvc)[0]
    const userInput = {
      year: prevStage === 'vin' ? predictedUserInput.year : year,
      province,
      make: prevStage === 'vin' ? predictedUserInput.make : make,
      model: prevStage === 'vin' ? predictedUserInput.model : model,
      trim: prevStage === 'vin' ? predictedUserInput.trim : (trim === 1 ? 'Not sure' : trim),
      mileage,
      vin: prevStage === 'vin' ? vin : '',
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number: acceptedPhoneNumber
    }
    setIsLoading(true)
    const adValues = utilExtractors.extractAdValues()
    serviceVehicles.get(uvc, userInput, adValues).then(
      data => onDone(data, userInput)
    ).catch(
      () => {
        setIsLoading(false)
        if (uvc === null) {
          onDone({}, userInput)
        }
      }
    )
  }

  const handleClickPrevStage = () => {
    if (stage !== 'user') {
      return
    }
    setStage(prevStage)
  }

  return (
    <div className={s['form'] + ' ' + s[`form--${stage}-stage`]}>
      <Loader isOpen={isLoading} />
      <p className={s['form__title']}>{titles[stage]}</p>
      <div className={s['form__indicators-wrapper']}>
        <div
          className={s['form__indicator'] + (stage !== 'user' ? ` ${s['form__indicator--active']}` : ` ${s['form__indicator--clickable']}`)}
          onClick={handleClickPrevStage}
        >
        </div>
        <div className={s['form__indicator'] + (stage === 'user' ? ` ${s['form__indicator--active']}` : '')}></div>
      </div>
      <div className={s['form__inputs-wrapper']}>
        {stage === 'vehicle' && (
          <>
            <div className={s['form__year-province-dropdown-wrapper']}>
              <Dropdown
                title='Year'
                required
                options={yearOptions}
                value={year}
                onChange={handleChangeYear}
              />
              <Dropdown
                title='Province'
                required
                options={utilConstants.provinceOptions}
                value={province}
                onChange={value => setProvince(value)}
              />
            </div>
            <Dropdown
              title='Make'
              required
              options={makeOptions}
              value={make}
              onChange={handleChangeMake}
            />
            <Dropdown
              title='Model'
              required
              options={modelOptions}
              value={model}
              onChange={handleChangeModel}
            />
            <Dropdown
              title='Trim'
              required
              options={trimOptions && trimOptions}
              value={trim}
              onChange={handleChangeTrim}
            />
            <Input
              title='Mileage (km)'
              required
              value={mileage}
              isError={isErrorMileage}
              onChange={handleChangeMileage}
            />
          </>
        )}
        {stage === 'vin' && (
          <>
            <Dropdown
              title='Province'
              required
              options={utilConstants.provinceOptions}
              value={province}
              onChange={value => setProvince(value)}
            />
            <Input
              title='Mileage (km)'
              required
              value={mileage}
              isError={isErrorMileage}
              onChange={handleChangeMileage}
            />
            <Input
              title='Enter your VIN number'
              required
              value={vin}
              isError={isErrorVin}
              errorMessage='The car was not found in the database'
              onChange={handleChangeVin}
            />
            <p className={s['form__remaining-text']}>{17 - vin.length} characters remaining</p>
          </>
        )}
        {stage === 'user' && (
          <>
            <Input
              title='First Name'
              required
              value={firstName}
              onChange={value => setFirstName(value)}
            />
            <Input
              title='Last Name'
              required
              value={lastName}
              onChange={value => setLastName(value)}
            />
            <Input
              title='E-mail'
              required
              value={email}
              isError={isErrorEmail}
              onChange={handleChangeEmail}
            />
            <Input
              title='Phone number'
              required
              value={phoneNumber}
              isError={isErrorPhoneNumber}
              onChange={handleChangePhoneNumber}
            />
          </>
        )}
      </div>
      <div className={s['form__controls-wrapper'] + (stage === 'user' ? ` ${s['form__controls-wrapper--user-stage']}` : '')}>
        {stage === 'vehicle' && (
          <>
            <p
              className={s['form__link']}
              onClick={handleClickLinkVehicleStage}
            >
              Use my VIN instead
            </p>
            <Button
              isDisable={isDisableContinueButtonVehicleStage}
              onClick={handleClickContinueButtonVehicleStage}
            >
              Continue
            </Button>
          </>
        )}
        {stage === 'vin' && (
          <>
            <p
              className={s['form__link']}
              onClick={handleClickLinkVinStage}
            >
              Use vehicle details
            </p>
            <Button
              isDisable={isDisableContinueButtonVinStage}
              onClick={handleClickContinueButtonVinStage}
            >
              Continue
            </Button>
          </>
        )}
        {stage === 'user' && (
          <>
            <img
              className={s['form__icon-prev-stage']}
              src={iconPrevStage}
              alt='Prev stage'
              onClick={handleClickPrevStage}
            />
            <Button
              className={s['form__button'] + ' ' + s[`form__button--${stage}-stage`]}
              isDisable={isDisableShowMeValuationResultButtonUserStage}
              onClick={handleClickShowMeValuationResultButtonUserStage}
            >
              Send me the estimated offer!
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default Form
