import serviceApi from 'services/api'

const baseURL = 'vehicles'

const getUvc = vin => serviceApi.get(`${baseURL}/${vin}/uvc`)

const get = (uvc, userInput, adValues) => serviceApi.post(`${baseURL}/${uvc}`, {...userInput, adValues})

const service = {
  getUvc,
  get
}

export default service
