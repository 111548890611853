import { useState, useEffect } from "react";
import Header from "components/Header";
import Survey from "components/Survey";
import Result from "components/Result";
import serviceContactRequests from "services/contact-requests";
import utilFormattes from "utils/formatter";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [vehicle, setVehicle] = useState({});
  const [userInput, setUserInput] = useState({});

  useEffect(() => {
    const referralId = new URLSearchParams(window.location.search).get(
      "referralId"
    );
    if (referralId) {
      localStorage.setItem("referralId", referralId);
    }
    const result = new URLSearchParams(window.location.search).get('result')
    if (!result) {
      setIsLoading(false)
      return
    }
    try {
      const { vehicle, userInput } = JSON.parse(atob(result));
      handleDoneSurvey(vehicle, userInput)
    } catch {}
    setIsLoading(false);
  }, []);

  const handleDoneSurvey = (vehicle, userInput) => {
    setVehicle({
      ...vehicle,
      minPrice: vehicle.min_price,
      maxPrice: vehicle.max_price,
    });
    setUserInput(userInput);
  };

  const handleDoneResult = (contactMethod, contactTime) =>
    serviceContactRequests.create({
      ...userInput,
      contact_method: contactMethod,
      contact_time: contactTime,
      price:
        `$${utilFormattes.prettyString(vehicle.minPrice, ",", 3)}` +
        (vehicle.maxPrice
          ? ` - $${utilFormattes.prettyString(vehicle.maxPrice, ",", 3)}`
          : ""),
    });

  const reset = () => {
    setVehicle({});
    setUserInput({});
  };

  const trim = vehicle.trim || userInput.trim;

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <Header />
      {!["", undefined].includes(vehicle.uvc) ? (
        <Result
          year={vehicle.year || userInput.year}
          make={vehicle.make || userInput.make}
          model={vehicle.model || userInput.model}
          trim={trim !== "Not sure" ? trim : ""}
          mileage={userInput.mileage}
          minPrice={vehicle.minPrice}
          maxPrice={vehicle.maxPrice}
          image={vehicle.image}
          email={userInput.email}
          firstName={userInput.first_name}
          lastName={userInput.last_name}
          phoneNumber={userInput.phone_number}
          onDone={handleDoneResult}
          onReset={reset}
        />
      ) : (
        <Survey onDone={handleDoneSurvey} />
      )}
    </>
  );
};

export default App;
