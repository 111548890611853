import serviceApi from 'services/api'

const baseURL = 'contact_requests'

const create = userInput => serviceApi.post(baseURL, userInput)

const service = {
  create
}

export default service
