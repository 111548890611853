import axios from 'axios'

const getReferralIdParam = () => localStorage.getItem('referralId')
  ? { referralId: localStorage.getItem('referralId') }
  : {}

const makeRequest = ({
  headers = {},
  params = {},
  ...config
}) => axios({
  ...config,
  url: process.env.REACT_APP_API_URL + config.url,
  headers: {
    ...headers,
    'Content-Type': headers['Content-Type'] ? headers['Content-Type'] : 'application/json'
  },
  params: {
    ...params,
    ...getReferralIdParam()
  }
}).then(
  ({ data }) => data
)

const get = (url, params = {}) => makeRequest({ method: 'GET', url, params })

const post = (url, data = {}) => makeRequest({ method: 'POST', url, data })

const service = {
  get,
  post
}

export default service
