import s from './index.module.scss'

const Input = ({
  title = '',
  required = false,
  value = '',
  isError = false,
  errorMessage = '',
  onChange = () => {},
}) => (
  <div className={s['input']}>
    <p className={s['input__title'] + (required ? ` ${s['input__title--required']}` : '')}>{title}</p>
    <input
      value={value}
      onChange={({ target: { value } }) => onChange(value)}
      className={s['input__input'] + (isError ? ` ${s['input__input--error']}` : '')}
    />
    {isError && <p className={s['input__error']}>{errorMessage}</p>}
  </div>
)

export default Input
