import serviceApi from 'services/api'

const baseURL = 'specifications'

const get = () => serviceApi.get(baseURL)

const service = {
  get
}

export default service
