import { useState } from "react";
import utilFormattes from "utils/formatter";
import utilExtractors from "utils/extractors";
import imageVehicleThumbnailPlaceholder from "assets/images/vehicle-thumbnail-placeholder.png";
import imageVehicleThumbnailPlaceholder2x from "assets/images/vehicle-thumbnail-placeholder@2x.png";
import imageVehicleThumbnailPlaceholder3x from "assets/images/vehicle-thumbnail-placeholder@3x.png";
import s from "./index.module.scss";

const Result = ({
  year = "",
  make = "",
  model = "",
  trim = "",
  mileage = "",
  minPrice = 0,
  maxPrice = 0,
  image = null,
  email = "",
  firstName = "",
  phoneNumber = ""
}) => {
  const iframeParams = [`pauseautoplay`, `hideprogresscontrols`];
  const [src, setSrc] = useState(
    image ? image.link : imageVehicleThumbnailPlaceholder
  );
  const [srcSet, setSrcSet] = useState(
    image
      ? ""
      : `${imageVehicleThumbnailPlaceholder2x} 2x, ${imageVehicleThumbnailPlaceholder3x} 3x`
  );
  const isFullInfo = minPrice || maxPrice;

  const handleClickEvaluateAnotherCar = () => {
    const adValues = utilExtractors.extractAdValues();
    window.open(`${window.location.origin}/?${Object.keys(adValues).map(adKey => adKey + '=' + adValues[adKey]).join('&')}`, "_blank").focus();
  };

  const handleErrorPhoto = () => {
    setSrc(imageVehicleThumbnailPlaceholder);
    setSrcSet(
      `${imageVehicleThumbnailPlaceholder2x} 2x, ${imageVehicleThumbnailPlaceholder3x} 3x`
    );
  };

  return (
    <div className={s["result"]}>
      <div className={s[`result__wrapper${isFullInfo ? "" : "_modified"}`]}>
        <div className={s["result__info-wrapper"]}>
          <img
            className={s["result__photo"]}
            src={src}
            srcSet={srcSet}
            alt="Vehicle"
            onError={handleErrorPhoto}
          />
          <div className={s[`result__info${isFullInfo ? "" : "_modified"}`]}>
            <p className={s[`result__title${isFullInfo ? "" : "_modified"}`]}>
              Estimated car value
            </p>
            <p className={s[`result__make${isFullInfo ? "" : "_modified"}`]}>
              {year} {make} {model}
            </p>
            <p className={s[`result__trim${isFullInfo ? "" : "_modified"}`]}>
              {trim ? `${trim}; ` : ""}
              {utilFormattes.prettyString(mileage, ",", 3)} KM
            </p>
            {isFullInfo ? (
              <>
                <p className={s["result__trade-in-price-title"]}>
                  Trade-in Price{" "}
                </p>
                <p className={s["result__trade-in-price"]}>
                  $
                  {utilFormattes.prettyString(
                    parseInt(minPrice * 1.13),
                    ",",
                    3
                  )}
                  {maxPrice ? (
                    ` - $${utilFormattes.prettyString(
                      parseInt(maxPrice * 1.13),
                      ",",
                      3
                    )}`
                  ) : (
                    <></>
                  )}
                </p>
                <p className={s["result__direct-sale-price-title"]}>
                  Direct Sale Price
                </p>
                <p className={s["result__direct-sale-price"]}>
                  ${utilFormattes.prettyString(minPrice, ",", 3)}
                  {maxPrice ? (
                    ` - $${utilFormattes.prettyString(maxPrice, ",", 3)}`
                  ) : (
                    <></>
                  )}
                </p>
                <p className={s["result__description"]}>
                  This evaluation can change (higher or lower) based on your
                  car’s exact condition, history and any additional information
                  we receive after our discussions.
                </p>
              </>
            ) : (
              <p className={s["result__text-info"]}>
                We will need more information about your car to estimate the
                price. We’re going to contact you shortly, or you can speed up
                the process by clicking the button below.
              </p>
            )}
            <p
              className={s["result__link"]}
              onClick={handleClickEvaluateAnotherCar}
            >
              Evaluate another car
            </p>
          </div>
        </div>
        <div className={s["result__guide-wrapper"]}>
          <div className={s["contact-modal__frame"]}>
            <iframe
              title="videoAsk-title"
              id="iframe-id-element"
              autoPlay="0"
              src={`https://www.videoask.com/f9j346p2e?${iframeParams.join(
                "&"
              )}#contact_name=${firstName}&contact_email=${email}&contact_phone_number=${utilFormattes.truncatePhoneNumber(
                phoneNumber
              )}`}
              allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
              className={s["contact-modal__frame-video"]}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
