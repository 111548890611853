import Select from 'react-select'
import iconDropdownIndicator from 'assets/icons/dropdown-indicator.svg'
import s from './index.module.scss'

const Indicator = ({ selectProps: { menuIsOpen } }) => (
  <img
    className={
      s['indicator']
      + (menuIsOpen ? ` ${s['indicator--open']}` : '')
    }
    src={iconDropdownIndicator}
    alt='Toggle'
  />
)

const styles = {
  control: (provided, state) => {
    const underline = state.menuIsOpen && {
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 13,
        width: 'calc(100% - 45px)',
        height: 1,
        backgroundColor: '#cfdeff'
      }
    }

    return {
      ...provided,
      borderRadius: state.menuIsOpen ? '6px 6px 0px 0px' : 6,
      border: 'solid 1px #5c57ff',
      borderBottom: state.menuIsOpen ? 'unset' : 'solid 1px #5c57ff',
      boxShadow: 'unset',
      cursor: 'pointer',
      width: '100%',
      height: 43,
      display: 'grid',
      gridTemplateColumns: '1fr 16px',
      padding: '0 2px 0 10px',
      backgroundColor: '#f7f7f7',
      transition: 'all 200ms ease-in-out',
      '&:hover': {
        border: 'solid 1px #5c57ff',
        borderBottom: state.menuIsOpen ? 'unset' : 'solid 1px #5c57ff',
      },
      ...underline
    }
  },
  valueContainer: provided => ({
    ...provided,
    padding: 0,
    fontSize: 13,
    display: 'grid',
    alignItems: 'center',
  }),
  singleValue: provided => ({
    ...provided,
    padding: 0,
    overflow: 'unset',
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  menu: provided => ({
    ...provided,
    margin: 0,
    boxShadow: 'unset',
    borderRadius: '0 0 6px 6px',
    border: 'solid 1px #5c57ff',
    borderTop: 'unset',
    backgroundColor: '#f7f7f7',
    overflow: 'hidden',
  }),
  menuList: provided => ({
    ...provided,
    maxHeight: 149,
    padding: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#cfdeff' : '#f7f7f7',
    color: '#1c1c1c',
    cursor: state.isSelected ? 'default' : 'pointer',
    padding: '8px 13px',
    fontSize: 13,
    '&:active': {
      backgroundColor: '#cfdeff'
    }
  })
}

const Dropdown = ({
  title = '',
  required = false,
  options = [],
  value = '',
  onChange = () => {},
}) => (
  <div className={s['dropdown']}>
    <p
      className={
        s['dropdown__title']
        + (required ? ` ${s['dropdown__title--required']}` : '')
      }
    >
      {title}
    </p>
    {
      options.length
        ? (
          <Select
            options={options}
            value={value && options.filter(option => option.value === value)[0]}
            isSearchable={false}
            placeholder=''
            components={{ DropdownIndicator: Indicator }}
            styles={styles}
            onChange={({ value }) => onChange(value)}
          />
        )
        : <div className={s['dropdown__mock']}></div>
    }
    
  </div>
)

export default Dropdown
