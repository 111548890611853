import utilConstants from './constants'

const extractAdValues = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const result = {}
  utilConstants.adKeys.forEach(adKey => {
    const value = urlParams.get(adKey)
    if (!value) {
      return
    }
    result[adKey] = value
  })
  return result
}

const util = {
  extractAdValues
}

export default util
